import { ConferenceBadgeData } from 'api-client/api';
import { IDataParsingService } from '../../interfaces';

export class AuvsiDataParsingService implements IDataParsingService {
  parseBadgeData(data: string): ConferenceBadgeData {
    const dataArr = data.split('|');
    return {
      firstName: dataArr[2],
      lastName: dataArr[3],
      company: '',
      id: dataArr[0],
    };
  }
}
