import { ConferenceParticipantData } from 'api-client/api';
import { IConferenceDataService, IDataParsingService } from 'modules/recordLead/core/interfaces';
import { DefaultApi } from 'api-client/api';
import { IMetadataService } from 'modules/common/core/interfaces';

export class ConferenceApiDataService implements IConferenceDataService {
  /**
   *
   */
  private api: DefaultApi;

  constructor(
    private services: {
      metadataService: IMetadataService;
    },
    private dataParsingService: IDataParsingService
  ) {
    this.api = new DefaultApi();
  }

  async fetchConferenceParticipantData(input: string): Promise<ConferenceParticipantData | undefined> {
    const participantData = this.dataParsingService.parseBadgeData(input);
    try {
      const response = await this.api.getLeadExpoData(
        participantData.id,
        participantData.lastName,
        this.services.metadataService.getExpoData().expoId,
        localStorage.getItem('token') as string
      );
      const data = <ConferenceParticipantData>await response.json();
      const countryCode = this.extractCountryCode(data.country ?? '');
      if (response.status >= 200 || response.status < 300) {
        return {
          ...data,
          countryCode,
        };
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  }

  private extractCountryCode(countryName: string): string {
    if (countryName.length === 2) return countryName.toUpperCase();
    const countries = this.services.metadataService.getCountries();

    return countries.find((c) => c.name === countryName)?.iso2AlphaCode ?? '';
  }
}
