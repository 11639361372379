import { ConferenceBadgeData } from 'api-client/api';
import { IDataParsingService } from 'modules/recordLead/core/interfaces';

export class SpieDataParsingService implements IDataParsingService {
  parseBadgeData(data: string): ConferenceBadgeData {
    const dataArr = data.split('$');
    return {
      firstName: dataArr[2],
      lastName: dataArr[3],
      company: dataArr[4],
      id: dataArr[0],
    };
  }
}
